import React from 'react'
import Link from 'gatsby-link'

import './style.scss'
import mellow_logo from '../../../static/img/mellow-logo.png'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'

const PressPage = () => (
  <div id="press-page" className="base">
    <div className="press-page page-title">
      <div className="container">
        <h2>Press</h2>
        <div className="img-holder">
          <img src={mellow_logo} className="logo" />
        </div>
        <h5>Contact</h5>
        <p>team@borrowmellow.com</p>
      </div>
    </div>
  </div>
)

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="Press" />
    <PressPage />
  </Layout>
)
